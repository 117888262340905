<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->


<template>
  <div >
    <label :class="addLabelClasses">{{label}}</label>

    <div class="ml-2 mt-2">
      <CCard
        class="mb-2"
        v-for="(item, i) in list"
        :accentColor="accordion === i ? 'primary': ''"
        :key="item.id"
      >
        <CCardHeader
          class="border-bottom-0 collapse_item_header"
          @click="accordion = accordion === i ? false : i"
        >
          <strong>{{ item[itemNameAttr] || `${itemNewName} ${i + 1}` }}</strong>

        <CBadge class="mx-2" color="primary" v-if="item.id === channelAdminId">
          {{ $t('channel.adminBadge') }}
        </CBadge>
        <CBadge class="mx-2" color="primary" v-if="item.id === chaincodeAdminId">
          {{ $t('channel.chaincodeAdminBadge') }}
        </CBadge>

          <div class="card-header-actions">
            <CButton @click.stop.prevent="deleteItem(i)" size="sm" class="p-0 m-0  mr-4" >
              <CIcon name="cil-trash" size="sm" />
            </CButton>
            <CButton size="sm" class="p-0 m-0" >
              <fa-icon icon="angle-up" size="lg" v-if="accordion === i" />
              <CIcon name="cil-pencil" size="sm" v-if="accordion !== i" />
            </CButton>
          </div>
        </CCardHeader>
        <CCollapse :show="accordion === i">
          <CCardBody class="border-top">
            <OrgForm
              v-model="list[i]"
              :id="item.id"
              :allOrganizations="organizations"
              :itemList="list"
              @selectOrganization="onSelectOrganization(i, $event)"
            />
          </CCardBody>
        </CCollapse>
      </CCard>

      <CButton
        color="dark"
        variant="outline"
        @click="addItem"
      >
        <CIcon name="cil-plus"/> {{ addButtonLabel }}
      </CButton>
    </div>
  </div>
</template>

<script>
import OrgForm from './OrgForm.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'FormObjectsCollapse',

  data() {
    return {
      accordion: false,
    }
  },

  components: {
    OrgForm
  },

  props: {
    label: String,
    addLabelClasses: String,
    addButtonLabel: String,
    itemNameAttr: {
      type: String,
      default: 'name'
    },
    itemNewName: String,
    list: Array,
    isHideAddOrganization: Boolean,
    organizations: Array,
    channelAdminId: String,
    chaincodeAdminId: String
  },

  computed: {
    ...mapGetters([
      'allOrganizations',
    ]),
  },

  methods: {
    addItem () {
      const participant = {id: '' + Date.now()}
      this.$emit('addParticipant', participant)
      this.accordion = this.list.length - 1;
    },

    deleteItem (i) {
      this.$emit('deleteParticipant', i)
    },

    onSelectOrganization(i, organization) {
      this.$emit('changeParticipants', {i, organization})
    },

  },
};
</script>
<style>
    .collapse_item_header {cursor:pointer}
    .collapse_item_header.card-header:first-child {
      border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) calc(0.25rem - 1px) calc(0.25rem - 1px);
    }
</style>

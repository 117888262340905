<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->


<template>
  <div>
    <template v-if='allOrganizations.length'>
      <CSelect
        :label="$t('channel.organization')"
        :options="orgSelectValues"
        horizontal
        required
        :placeholder="$t('channel.selectOrg')"
        class="mb-1"
        :value.sync="selectedOrganizationId"
        @change="selectOrganization"
      />
    </template>
    <template v-else>
      <CRow class="mb-3">
        <CCol>
          <CAlert color="warning">{{$t('channel.orgAbsent')}}</CAlert>
        </CCol>
      </CRow>
    </template>

    <!-- Организация уже выбрана-->
    <template v-if="isOrganizationExist">
      <CRow class="mb-3 mt-3" v-if="!CACertsExists">
        <CCol sm="3"></CCol>
        <CCol sm="9">
          <CAlert color="warning">{{$t('channel.noCert')}}</CAlert>
        </CCol>
      </CRow>
      <template v-else >
        <CRow class="mb-3">
          <CCol sm="3">
            {{$t('channel.sertCa')}}
          </CCol>
          <CCol sm="9">
            <certificate
              :org-id="selectedOrganizationId"
              :type-of="certificateTypes.cacerts.code"
              :cert-id="cacert"
              :text-if-is="true"
            ></certificate>
          </CCol>
        </CRow>
        <CRow class="mb-3">
          <CCol sm="3">
            {{$t('channel.sertTls')}}
          </CCol>
          <CCol sm="9">
            <certificate
              :org-id="selectedOrganizationId"
              :type-of="certificateTypes.tlscacerts.code"
              :cert-id="tlscacert"
              :text-if-is="true"
            ></certificate>
          </CCol>
        </CRow>
      </template>

      <!-- <div role="group" class="form-group form-row" v-for="(item, i) in admincerts" :key="i">
        <CCol sm="3">
          {{$t('channel.sertAdmin')}} {{ admincerts.length > 1 ? (i+1) : '' }}
        </CCol>
          <CCol sm="9">
            <certificate
              :org-id="selectedOrganizationId"
              :type-of="certificateTypes.admincerts.code"
              ref="adminCert"
              :text-if-is="true"
              :cert-id="item"
            ></certificate>
          </CCol>
      </div> -->

    </template>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Certificate from '../certificates/Certificate.vue'
import {CertificateTypes} from "../certificates/constants"


export default {
  name: 'OrgForm',
  components: {
    Certificate,
  },

  props: {
    id: String,
    allOrganizations: Array,
    itemList: Array,
  },

  data() {
    return {
      selectedOrganizationId: this.id,
    }
  },

  computed: {
    ...mapGetters([
      'getOrganization',
    ]),
    certificateTypes() {
      return CertificateTypes
    },
    selectedOrganization() {
      return this.getOrganization(this.selectedOrganizationId)
    },
    cacert() {
      return this.selectedOrganization ? this.selectedOrganization.msp[this.certificateTypes.cacerts.code][0] : ''
    },
    tlscacert() {
      return this.selectedOrganization ? this.selectedOrganization.msp[this.certificateTypes.tlscacerts.code][0] : ''
    },
    admincerts() {
      return this.selectedOrganization ? this.selectedOrganization.msp[this.certificateTypes.admincerts.code] : []
    },

    isOrganizationExist() {
      return this.selectedOrganization != null
    },

    organizationsForSelect() {
      let indexArr = this.itemList.map(item => item.id)
      return this.allOrganizations.filter(item => {
        return item.id === this.selectedOrganizationId || !(indexArr.includes(item.id))
      })
    },

    orgSelectValues(){
      let orgs = [];
      this.organizationsForSelect.forEach( (item) => {
        orgs.push({
          'value': item.id,
          'label': item.mspid
        })
      })
      return orgs
    },

    CACertsExists() {
      if (this.selectedOrganizationId) {
        const org = this.getOrganization(this.selectedOrganizationId)
        return Boolean(
          org &&
          org.msp &&
          org.msp[this.certificateTypes.cacerts.code] &&
          org.msp[this.certificateTypes.tlscacerts.code] &&
          org.msp[this.certificateTypes.cacerts.code].length &&
          org.msp[this.certificateTypes.tlscacerts.code].length
        )
      }
      return false
    },

  },

  methods: {
    selectOrganization() {
      this.$emit('selectOrganization', this.selectedOrganization)
    },

  },

};
</script>


<style scoped>
  .footer-buttons button {
    margin-left: 5px;
  }

</style>

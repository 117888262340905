<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->


<template>
  <CRow>
    <CCol>
      <CForm>
        <ModalParam :blockchain_net_id="channel.blockchain_net_id" 
          :isShowModal="isShowModal"
          :ordererList="modalParam.ordererList" 
          :fabricAdminToolList="modalParam.fabricAdminToolList"
          :peerList="modalParam.peerList"
          :shouldSelectChainCodeAdmin="shouldSelectChainCodeAdmin"
          :shouldSelectChannelAdmin="shouldSelectChannelAdmin"
          :participants="channel.participants"
          @closeModal="onCloseModal" 
          @createParam="onCreateParam" />
        
        <CCard>
          <CCardHeader>
            <strong>
              {{ $t('channel.channel') }} {{ channel.code_name }}
              <CBadge :color="badgeStyle">
                {{ badgeText }}
              </CBadge>
            </strong>
          </CCardHeader>
          <CCardBody>
            <template>
              <CInput :label="$t('channel.programId')" horizontal addLabelClasses="font-weight-bold"
                v-model="channel.code_name" :plaintext="true" required />
              <CInput :label="$t('channel.bchChannel')" horizontal addLabelClasses="font-weight-bold"
                v-model="channel.blockchain_net_name" :plaintext="true" required />
              <CInput :label="$t('channel.policyChangeState')" horizontal addLabelClasses="font-weight-bold"
                v-model="blockchainConfigName" :plaintext="true" required />

              <CInput :label="$t('channel.policyLifeCycle')" horizontal addLabelClasses="font-weight-bold"
                v-model="lifeCycleConfigName" :plaintext="true" required />

            </template>

            <template>
              <FormObjectsCollapse class="mb-4" 
                addLabelClasses="font-weight-bold"
                :label="$t('channel.participants')" 
                :addButtonLabel="$t('channel.addParticipant')" 
                :itemNewName="$t('channel.participant')" 
                :list="channel.participants" 
                :organizations="organizations"
                :channelAdminId="channel.channel_administrator_org_id"
                :chaincodeAdminId="channel.chaincode_administrator_org_id"
                :itemNameAttr="['name', 'mspid']"
                @changeParticipants="onChangeParticipant" 
                @deleteParticipant="onDeleteParticipant"
                @addParticipant="onAddParticipant"/>

            </template>
          </CCardBody>
          <CCardFooter>
            <div class="tooltip-wrapper" v-if="renderComponent" v-c-tooltip="{
              content: tooltipPublish,
              placement: 'top',
              html: true,
            }">
              <CButton type="submit" color="success" class="mr-4" :disabled="isCreateDisabled"
                @click.prevent="publishChannel">
                <CIcon name="cil-check-circle" /> {{ labelCreate }}
              </CButton>
            </div>
            <div class="tooltip-wrapper" v-if="renderComponent" v-c-tooltip="{
              content: tooltipSave,
              placement: 'top',
              html: true,
            }">
              <CButton color="primary" class="mr-4" :disabled="isNotChanged" @click.prevent="saveDraft">
                <CIcon name="cil-pencil" /> {{ $t('common.saveDraft') }}
              </CButton>
            </div>

            <CLink v-if="isNotChanged" class="text-danger" @click.prevent="deleteDraft">{{ labelDelete }}</CLink>
            <CLink v-else class="text-danger" @click.prevent="cancelDraft">{{ $t('channel.cancelDraft') }}
            </CLink>

          </CCardFooter>
        </CCard>
      </CForm>
    </CCol>
  </CRow>
</template>

<script>
import utilMessages from '../../../utils/messages';
import { sameArray } from "../../../utils/system";
import { ChannelConfigUpdateType } from '../common/transaction/constants';
import { ChannelUpdateStatus } from './constants';
import FormObjectsCollapse from "./FormObjectsCollapse.vue";
import ModalParam from "./ModalParam.vue";

export default {
  name: 'ChannelDraft',
  components: {
    FormObjectsCollapse,
    ModalParam
  },

  data() {
    return {
      renderComponent: true,
      channel: {
        blockchain_net_id: null,
        participants: []
      },
      participantsOld: null,
      organizations: [],
      isShowModal: false,
      modalParam: {
        ordererList: [],
        fabricAdminToolList: [],
        peerList: []
      }
    }
  },

  watch: {
    tooltipSave(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.forceRerender()
      }
    },

    tooltipPublish(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.forceRerender()
      }
    }
  },
  async mounted() {
    await this.getChannel()
    this.participantsOld = [...this.channel.participants]
  },

  computed: {
    channelUpdateStatus() {
      return ChannelUpdateStatus
    },
    badgeStyle() {
      const status = this.channel.update_status
      return status ? this.channelUpdateStatus[this.channel.update_status].style : ''
    },
    badgeText() {
      const status = this.channel.update_status
      return status ? this.channelUpdateStatus[this.channel.update_status].text : ''
    },
    participantsDraft() {
      return this.channel.participants.filter(item => item.mspid)
    },
    isCreateDisabled() {
      const participantsEmpty = this.participantsDraft ? this.participantsDraft.length === 0 : true
      const startedNotChanged = this.channel.is_started ? this.isNotChanged : false
      return participantsEmpty || startedNotChanged
    },
    isNotChanged() {
      return sameArray(this.participantsDraft, this.participantsOld)
    },
    canSave() {
      return !this.isNotChanged
    },
    blockchainConfigName() {
      const channelBlockchainStatus = this.channel.channel_update_policy_type
      return channelBlockchainStatus ? ChannelConfigUpdateType[this.channel.channel_update_policy_type].text : ''
    },
    lifeCycleConfigName() {
      const channelBlockchainStatus = this.channel.lifecycle_update_policy_type
      return channelBlockchainStatus ? ChannelConfigUpdateType[this.channel.lifecycle_update_policy_type].text : ''
    },
    labelCreate() {
      return this.channel.is_started ? this.$i18n.t('channel.changeChannel') : this.$i18n.t('channel.createChannel')
    },
    labelDelete() {
      return this.channel.is_started ? this.$i18n.t('channel.cancelDraft') : this.$i18n.t('channel.removeChannel')
    },
    tooltipSave() {
      let message = '';

      if (!this.canSave) {
        if (this.isNotChanged)
          message = this.$i18n.t('common.noChanges')
      }
      if (!message)
        message = this.$i18n.t('channel.saveWithoutPublish')

      return message
    },
    tooltipPublish() {
      let message = '';
      const participantsEmpty = this.participantsDraft ? this.participantsDraft.length === 0 : true
      const startedNotChanged = this.channel.is_started ? this.isNotChanged : false

      if (participantsEmpty) {
        message = this.$i18n.t('channel.publishMsg[0]')
        message += this.$i18n.t('channel.publishMsg[1]')
      }

      if (startedNotChanged) {
        message = this.$i18n.t('channel.publishMsg[2]')
      }

      if (!message)
        if (this.canSave)
          message = this.$i18n.t('channel.publishMsg[3]')
        else
          if (this.channel.is_started)
            message = this.$i18n.t('channel.publishMsg[4]')
          else
            message = this.$i18n.t('channel.publishMsg[5]')

      return message
    },
    shouldSelectChainCodeAdmin() {
      return this.channel.lifecycle_update_policy_type === ChannelConfigUpdateType.EXCLUSIVE.code;
    },
    shouldSelectChannelAdmin() {
      return this.channel.channel_update_policy_type === ChannelConfigUpdateType.EXCLUSIVE.code;
    }
  },

  methods: {

    async getChannel() {
      if (this.$route.params.id) {
        const channelId = this.$route.params.id;
        try {
          await this.$store.dispatch('fetchDraftChannel', channelId)
          this.channel = this.$store.getters.getDraftChannel()
          await this.loadOrganizations(this.channel.blockchain_net_id)
        } catch (err) {
          this.$toast.error(utilMessages.errMessage(err))
        }

      }
    },

    async loadOrganizations(bchId) {
      try {
        const data = {
          view: 'channel',
          blockchain_net_id: bchId
        }
        const res = await this.$store.dispatch('fetchOrganizations', data)
        this.organizations = res
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err))
      }
    },

    onChangeParticipant({ i, organization }) {
      this.channel.participants.splice(i, 1, organization)
    },

    onDeleteParticipant(i) {
      this.channel.participants.splice(i, 1)
    },

    onAddParticipant(participant) {
      this.channel.participants.push(participant)
    },

    async publishChannel() {
      await this.getOrderer();
      await this.getFabricAdminTool();
      await this.getPeer();

      this.isShowModal = true;
    },

    async saveDraft() {
      const channel = { ...this.channel, participants: this.participantsDraft }
      const participantsIds = channel.participants.map(item => item.id)
      try {
        const result = await this.$store.dispatch('saveDraftChannel', {
          channelId: this.channel.id,
          data: { participants: participantsIds }
        })
        this.$toast.success(this.$i18n.t('channel.draftSaved'))
        this.channel = result

      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err))
      }
    },

    async deleteDraft() {
      try {
        await this.$store.dispatch('deleteDraftChannel', this.channel.id)
        this.$toast.success(this.$i18n.t('channel.channelDraftRemoved'))
        this.$router.push({ path: `/channels` })
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err))
      }
    },

    cancelDraft() {
      this.channel.participants = [...this.participantsOld]
    },

    async getOrderer() {
      try {
        const param = `blockchain_net_id=${this.channel.blockchain_net_id}&type=orderer`
        this.modalParam.ordererList = await this.$store.dispatch('fetchEndpoints', param)
      }
      catch (err) {
        this.$toast.error(utilMessages.errMessage(err))
      }
    },

    async getFabricAdminTool() {
      try {
        const param = `status=attached&current_org=true&type=fabric-admin-tools`
        this.modalParam.fabricAdminToolList = await this.$store.dispatch('fetchEndpoints', param)
      }
      catch (err) {
        this.$toast.error(utilMessages.errMessage(err))
      }
    },

    async getPeer() {
      try {
        const param = `type=peer&current_org=true`
        this.modalParam.peerList = await this.$store.dispatch('fetchEndpoints', param)
      }
      catch (err) {
        this.$toast.error(utilMessages.errMessage(err))
      }
    },

    onCloseModal() {
      this.isShowModal = false
    },

    async onCreateParam(param) {
      // handler for onCreateParam (modal)
      // Publishes draft Channel or Updates Channel's transaction
      // Redirects to Topic results page
      try {
        if (this.canSave)
          await this.saveDraft()

        let topicId
        if (this.channel.is_started) {
          topicId = await this.$store.dispatch('CreateUpdateTransactChannel', { channelId: this.channel.id, data: param })
          this.$router.push({ path: `/channels/${this.channel.id}/change-config?topic_id=${topicId}` })
        } else {
          topicId = await this.$store.dispatch('publishDraftChannel', { channelId: this.channel.id, data: param })
          this.$router.push({ path: `/topics/${topicId}` })
        }

      }
      catch (err) {
        this.$toast.error(utilMessages.errMessage(err))
      }
    },

    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    }
  },
}
</script>

<style>
.hide-close-button button.close {
  display: none
}

.tooltip-wrapper {
  display: inline-block;
}
</style>

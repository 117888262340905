<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->


<template>
  <CModal :title="$t('channel.paramSendTrans')" :show="isShowModal" :closeOnBackdrop="false" class="hide-close-button">

    <div class="hauberk-form-group">
      <div class="hauberk-form-group__title">
        {{ $t('network.commomProperties') }}
      </div>


      <div class="hauberk-form-item hb-item d-flex">
        <div class="hb-item__label hb-label d-flex flex-column">
          <label class="hb-label__main" for="networkAdmin">Orderer*</label>
        </div>

        <CSelect class="hb-item__input" :options="ordererForSelect"
          :horizontal="{ label: 'col-sm-5', input: 'd-flex w-100' }" required placeholder=" "
          addLabelClasses="font-weight-bold" :value.sync="form.orderer" :isValid="isValid.orderer"
          :tooltipFeedback=false :invalidFeedback="validationMessages.required" @change="$v.form.orderer.$touch()" />
      </div>

      <div class="hauberk-form-item hb-item d-flex">
        <div class="hb-item__label hb-label d-flex flex-column">
          <label class="hb-label__main" for="networkAdmin">Fabric-admin-tool*</label>
        </div>

        <CSelect class="hb-item__input" :options="fabricAdminToolForSelect"
          :horizontal="{ label: 'col-sm-5', input: 'd-flex w-100' }" required placeholder=" "
          addLabelClasses="font-weight-bold" :value.sync="form.fabricAdminTool" :isValid="isValid.fabricAdminTool"
          :tooltipFeedback=false :invalidFeedback="validationMessages.required"
          @change="$v.form.fabricAdminTool.$touch()" />
      </div>

      <div class="hauberk-form-item hb-item d-flex">
        <div class="hb-item__label hb-label d-flex flex-column">
          <label class="hb-label__main" for="networkAdmin">Peer*</label>
        </div>

        <CSelect class="hb-item__input" :options="peerForSelect"
          :horizontal="{ label: 'col-sm-5', input: 'd-flex w-100' }" required placeholder=" "
          addLabelClasses="font-weight-bold" :value.sync="form.peer" :isValid="isValid.peer" :tooltipFeedback=false
          :invalidFeedback="validationMessages.required" @change="$v.form.peer.$touch()" />
      </div>
    </div>

    <div class="hauberk-form-group" v-if="shouldDisplayAdminSection">
      <div class="hauberk-form-group__title">
        {{ $t('network.admin') }}
      </div>

      <div class="hauberk-form-item hb-item d-flex" v-if="shouldSelectChainCodeAdmin">
        <div class="hb-item__label hb-label d-flex flex-column">
          <label class="hb-label__main" for="networkAdmin">{{ $t('channel.chaincodeAdminLabel') }}</label>
        </div>

        <CSelect class="hb-item__input" :options="participantsForSelect"
          :horizontal="{ label: 'col-sm-5', input: 'd-flex w-100' }" required placeholder=" "
          addLabelClasses="font-weight-bold" :value.sync="form.chainCodeAdmin" :isValid="isValid.chainCodeAdmin"
          :tooltipFeedback=false :invalidFeedback="validationMessages.required"
          @change="$v.form.chainCodeAdmin.$touch()" />
      </div>

      <div class="hauberk-form-item hb-item d-flex" v-if="shouldSelectChannelAdmin">
        <div class="hb-item__label hb-label d-flex flex-column">
          <label class="hb-label__main" for="networkAdmin">{{ $t('channel.channelAdmin') }}</label>
        </div>

        <CSelect class="hb-item__input" :options="participantsForSelect"
          :horizontal="{ label: 'col-sm-5', input: 'd-flex w-100' }" required placeholder=" "
          addLabelClasses="font-weight-bold" :value.sync="form.channelAdmin" :isValid="isValid.channelAdmin"
          :tooltipFeedback=false :invalidFeedback="validationMessages.required"
          @change="$v.form.channelAdmin.$touch()" />
      </div>
    </div>

    <div slot="footer" class="footer-buttons">
      <CButton type="button" class="btn btn-secondary" @click="closeModal"> {{ $t('common.cancel') }} </CButton>
      <CButton type="button" class="btn btn-primary" :disabled="!isFormValid" @click="createParam"> {{
          $t('common.create')
      }} </CButton>
    </div>
  </CModal>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators'
import messages from '../messages'


export default {
  name: 'ModalParam',

  props: {
    blockchain_net_id: String,
    isShowModal: Boolean,
    ordererList: Array,
    fabricAdminToolList: Array,
    peerList: Array,
    shouldSelectChainCodeAdmin: Boolean,
    shouldSelectChannelAdmin: Boolean,
    participants: Array
  },

  data() {
    return {
      validationMessages: messages.validation,
      form: {
        orderer: null,
        fabricAdminTool: null,
        peer: null,
        channelAdmin: null,
        chainCodeAdmin: null
      },
    }
  },
  validations: {
    form: {
      orderer: {
        required
      },
      fabricAdminTool: {
        required
      },
      peer: {
        required
      },
      channelAdmin: { required: requiredIf(function () { return this.shouldSelectChannelAdmin; }) },
      chainCodeAdmin: { required: requiredIf(function () { return this.shouldSelectChainCodeAdmin; }) },
    }
  },
  computed: {
    isValid() {
      let orderer, fabricAdminTool, peer, channelAdmin, chainCodeAdmin

      if (this.$v.form.orderer.$dirty) {
        orderer = !this.$v.form.orderer.$invalid
      }

      if (this.$v.form.fabricAdminTool.$dirty) {
        fabricAdminTool = !this.$v.form.fabricAdminTool.$invalid
      }

      if (this.$v.form.peer.$dirty) {
        peer = !this.$v.form.peer.$invalid
      }

      if (this.$v.form.channelAdmin.$dirty) {
        channelAdmin = !this.$v.form.channelAdmin.$invalid
      }

      if (this.$v.form.chainCodeAdmin.$dirty) {
        chainCodeAdmin = !this.$v.form.chainCodeAdmin.$invalid
      }


      return {
        orderer,
        fabricAdminTool,
        peer,
        channelAdmin,
        chainCodeAdmin
      }
    },
    isFormValid() {
      return !this.$v.form.$invalid
    },
    ordererForSelect() {
      return this.ordererList.map(item => {
        return {
          label: item.hostname,
          value: item.id
        }
      })
    },
    fabricAdminToolForSelect() {
      return this.fabricAdminToolList.map(item => {
        return {
          label: item.hostname,
          value: item.id
        }
      })
    },
    peerForSelect() {
      return this.peerList.map(item => {
        return {
          label: item.hostname,
          value: item.id
        }
      })
    },
    shouldDisplayAdminSection() {
      return this.shouldSelectChainCodeAdmin || this.shouldSelectChannelAdmin;
    },
    participantsForSelect() {
      return this.participants.map(item => ({
        label: item.name,
        value: item.id
      }))
    }
  },
  methods: {
    closeModal() {
      this.form = {
        orderer: null,
        fabricAdminTool: null,
        peer: null,
      }
      this.$v.$reset()
      this.$emit('closeModal')
    },
    createParam() {
      const data = {
        orderer_endpoint_id: this.form.orderer,
        admin_tool_endpoint_id: this.form.fabricAdminTool,
        peer_endpoint_id: this.form.peer,
        channel_administrator_org_id: this.form.channelAdmin,
        chaincode_administrator_org_id: this.form.chainCodeAdmin
      }

      this.$emit('createParam', data)
      this.closeModal()
    }
  },
};
</script>

<style scoped>
.footer-buttons button {
  margin-left: 5px;
}
</style>
